import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ADMINS_FEATURE_KEY, AdminsState, adminsAdapter } from '../reducers';

export const modulesState =
  createFeatureSelector<AdminsState>(ADMINS_FEATURE_KEY);

const { selectAll } = adminsAdapter.getSelectors();

export const isLoading = createSelector(
  modulesState,
  (state: AdminsState) => state.isLoading
);

export const admins = createSelector(modulesState, (state: AdminsState) =>
  selectAll(state)
);
