import { inject } from '@angular/core';
import { RedirectToService } from '@edgvr-front/util/common';
import { mergeMap, of } from 'rxjs';
import { ACCOUNT_ACCESS_REJECTED_URL } from '../../account-feature-acount-access.token';
import { AccountAccessService } from '../../services';

export const accountCompanyAdminAccessGuard = () => {
  const accountAccessService = inject(AccountAccessService);
  const redirectToService = inject(RedirectToService);
  const accountAccessRejectedUrl = inject(ACCOUNT_ACCESS_REJECTED_URL);

  return accountAccessService
    .accountCompanyAdminAccess()
    .pipe(
      mergeMap((_) =>
        _
          ? of(_)
          : redirectToService.redirect(accountAccessRejectedUrl).then(() => _)
      )
    );
};
