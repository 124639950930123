import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccountDataAccessModule } from '@edgvr-front/account/data-access';
import { AccountFeatureAccountAccessModule } from '@edgvr-front/account/feature/account-access';
import { CompaniesFeatureCompanyAccessModule } from '@edgvr-front/companies/feature/company-access';
import Components from './components';
import { CompaniesUtilCompanyUrlModule } from '@edgvr-front/companies/util/company-url';

@NgModule({
  declarations: [...Components],
  imports: [
    CommonModule,
    RouterModule,
    AccountDataAccessModule,
    AccountFeatureAccountAccessModule,
    CompaniesFeatureCompanyAccessModule,
    CompaniesUtilCompanyUrlModule,
  ],
  exports: [...Components],
})
export class EdgvrPortalFeatureHeaderModule {}
