import { Account } from '@edgvr-front/accounts/domain';
import { createReducer, on, Action } from '@ngrx/store';
import { AccountActions } from '../actions';

export const ACCOUNT_FEATURE_KEY = 'account';

export interface AccountState {
  isLoading: boolean;
  account: null | Account;
}

export const initialAccountState: AccountState = {
  isLoading: false,
  account: null,
};

const reducer = createReducer(
  initialAccountState,
  on(AccountActions.me, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AccountActions.meFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AccountActions.meSuccess, (state, { account }) => ({
    ...state,
    isLoading: false,
    account,
  })),
  on(AccountActions.signIn, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AccountActions.signInSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AccountActions.signInFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AccountActions.reSignIn, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AccountActions.reSignInSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AccountActions.reSignInFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AccountActions.signOut, (state) => ({
    ...state,
    isLoading: false,
    account: null,
  })),
  on(AccountActions.noAuthorized, (state) => ({
    ...state,
    isLoading: false,
    account: null,
  })),
  on(AccountActions.updateMe, (state, { update, account }) => ({
    ...state,
    isLoading: true,
    account: {
      id: account.id,
      ...update,
    },
  })),
  on(AccountActions.updateMeFailure, (state, { account }) => ({
    ...state,
    isLoading: false,
    account,
  })),
  on(AccountActions.updateMeSuccess, (state, { account }) => ({
    ...state,
    isLoading: false,
    account,
  })),
  on(AccountActions.resetPassword, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    AccountActions.resetPasswordFailure,
    AccountActions.resetPasswordSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(AccountActions.updatePassword, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    AccountActions.updatePasswordFailure,
    AccountActions.updatePasswordSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  )
);

export function accountReducer(
  state: AccountState | undefined,
  action: Action
) {
  return reducer(state, action);
}
