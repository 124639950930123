import { Injectable } from '@angular/core';
import {
  AccountJwt,
  ResetPassword,
  SignIn,
  UpdateMe,
  UpdatePassword,
} from '@edgvr-front/account/domain';
import { Account } from '@edgvr-front/accounts/domain';
import { ApiService } from '@edgvr-front/api/data-access';
import { TokenService } from '@edgvr-front/token/data-access';
import { jwtDecode } from 'jwt-decode';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class AccountService {
  constructor(
    private readonly apiService: ApiService,
    private readonly tokenService: TokenService
  ) {}

  get accountJwt$() {
    return this.tokenService.token$.pipe(
      distinctUntilChanged(),
      map((_) => (_ ? jwtDecode(_) : null)),
      map((_) => (_ && 'account' in _ ? (_['account'] as AccountJwt) : null))
    );
  }

  me() {
    return this.apiService
      .get<{ account: Account }>('me')
      .pipe(map((_) => _.account));
  }

  singIn(signIn: SignIn) {
    return this.apiService.post<{ token: string }>('auth/signin', {
      ...signIn,
    });
  }

  reSignIn() {
    return this.apiService.post<void>('auth/re-signin', {});
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.apiService.put<void>('me/reset-password', {
      ...resetPassword,
    });
  }

  updatePassword(updatePassword: UpdatePassword) {
    return this.apiService.put<void>('me/password', {
      ...updatePassword,
    });
  }

  updateMe(updateMe: UpdateMe) {
    return this.apiService.put<{ account: Account }>('me', {
      ...updateMe,
    });
  }
}
