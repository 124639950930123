import { AccountId } from '@edgvr-front/accounts/domain';
import { CompanyAccount } from '@edgvr-front/companies/domain';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { CompanyAccountsActions } from '../actions';

export const COMPANY_ACCOUNTS_FEATURE_KEY = 'company-accounts';

export interface CompanyAccountsState extends EntityState<CompanyAccount> {
  isLoading: boolean;
  isUpdating: boolean;
  selectedAccountId: null | AccountId;
}

export const companyAccountsAdapter: EntityAdapter<CompanyAccount> =
  createEntityAdapter<CompanyAccount>({
    sortComparer: (lhs: CompanyAccount, rhs: CompanyAccount) => lhs.id - rhs.id,
    selectId: (_) => _.id,
  });

export const initialCompanyAccountsState: CompanyAccountsState =
  companyAccountsAdapter.getInitialState({
    isLoading: false,
    isUpdating: false,
    selectedAccountId: null,
  });

const reducer = createReducer(
  initialCompanyAccountsState,
  on(CompanyAccountsActions.loadCompanyAccounts, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CompanyAccountsActions.loadCompanyAccountsSuccess, (state, { accounts }) =>
    companyAccountsAdapter.setAll(accounts, {
      ...state,
      isLoading: false,
    })
  ),
  on(CompanyAccountsActions.loadCompanyAccountsFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(CompanyAccountsActions.selectCompanyAccountSuccess, (state, { id }) => ({
    ...state,
    selectedAccountId: id,
  })),
  on(CompanyAccountsActions.unSelectCompanyAccountSuccess, (state) => ({
    ...state,
    selectedAccountId: null,
  })),
  on(CompanyAccountsActions.createCompanyAccount, (state) => ({
    ...state,
    isLoading: true,
    isUpdating: true,
  })),
  on(CompanyAccountsActions.createCompanyAccountSuccess, (state, { account }) =>
    companyAccountsAdapter.setOne(account, {
      ...state,
      isLoading: false,
      isUpdating: false,
    })
  ),
  on(CompanyAccountsActions.createCompanyAccountFailure, (state) => ({
    ...state,
    isLoading: false,
    isUpdating: false,
  })),
  on(CompanyAccountsActions.updateCompanyAccount, (state, { update }) =>
    companyAccountsAdapter.updateOne(
      {
        id: update.id,
        changes: update,
      },
      {
        ...state,
        isUpdating: true,
      }
    )
  ),
  on(CompanyAccountsActions.updateCompanyAccountSuccess, (state, { account }) =>
    companyAccountsAdapter.setOne(account, {
      ...state,
      isUpdating: false,
    })
  ),
  on(CompanyAccountsActions.updateCompanyAccountFailure, (state, { account }) =>
    companyAccountsAdapter.setOne(account, {
      ...state,
      isUpdating: false,
    })
  ),
  on(CompanyAccountsActions.deleteCompanyAccount, (state) => ({
    ...state,
    isLoading: true,
    isUpdating: true,
  })),
  on(
    CompanyAccountsActions.deleteCompanyAccountSuccess,
    (state, { accountId }) =>
      companyAccountsAdapter.removeOne(accountId, {
        ...state,
        isLoading: false,
        isUpdating: false,
      })
  ),
  on(CompanyAccountsActions.deleteCompanyAccountFailure, (state) => ({
    ...state,
    isLoading: false,
    isUpdating: false,
  })),
  on(CompanyAccountsActions.cleanCompanyAccounts, (state) =>
    companyAccountsAdapter.removeAll(state)
  ),
  on(CompanyAccountsActions.resetPasswordCompanyAccount, (state) => ({
    ...state,
    isLoading: true,
    isUpdating: true,
  })),
  on(
    CompanyAccountsActions.resetPasswordCompanyAccountFailure,
    CompanyAccountsActions.resetPasswordCompanyAccountSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      isUpdating: false,
    })
  )
);

export function companyAccountsReducer(
  state: CompanyAccountsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
