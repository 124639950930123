import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  NOTIFICATIONS_FEATURE_KEY,
  notificationsReducer,
} from './+state/reducers';
import { NotificationsFacade } from './+state/facades';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NOTIFICATIONS_FEATURE_KEY, notificationsReducer),
  ],
  providers: [NotificationsFacade],
})
export class NotificationsDataAccessModule {}
