import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COMPANIES_FEATURE_KEY,
  CompaniesState,
  companiesAdapter,
} from '../reducers';

export const companiesState = createFeatureSelector<CompaniesState>(
  COMPANIES_FEATURE_KEY
);

const { selectAll } = companiesAdapter.getSelectors();

export const isLoading = createSelector(
  companiesState,
  (state: CompaniesState) => state.isLoading
);

export const isUpdating = createSelector(
  companiesState,
  (state: CompaniesState) => state.isUpdating
);

export const companies = createSelector(
  companiesState,
  (state: CompaniesState) => selectAll(state)
);

export const selectedCompany = createSelector(
  companiesState,
  (state: CompaniesState) =>
    state.selectedCompanyId
      ? state.entities[state.selectedCompanyId] || null
      : null
);
