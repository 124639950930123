import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  Account,
  AccountId,
  CreateAccount,
  UpdateAccount,
} from '@edgvr-front/accounts/domain';
import { AdminsActions } from '../actions';
import { AdminsSelectors } from '../selectors';

@Injectable()
export class AdminsFacade {
  isLoading$ = this.store.pipe(select(AdminsSelectors.isLoading));
  admins$ = this.store.pipe(select(AdminsSelectors.admins));

  constructor(private readonly store: Store) {}

  loadAdmins() {
    this.store.dispatch(AdminsActions.loadAdmins());
  }

  createAdmin(createAdmin: CreateAccount) {
    this.store.dispatch(AdminsActions.createAdmin({ createAdmin }));
  }

  updateAdmin(data: { admin: Account; update: UpdateAccount }) {
    this.store.dispatch(AdminsActions.updateAdmin(data));
  }

  deleteAdmin(adminId: AccountId) {
    this.store.dispatch(AdminsActions.deleteAdmin({ adminId }));
  }

  resetPasswordAdmin(account: Account) {
    this.store.dispatch(AdminsActions.resetPasswordAdmin({ account }));
  }
}
