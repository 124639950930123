import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AccountFacade } from '@edgvr-front/account/data-access';
import { CompanyUrlService } from '@edgvr-front/companies/util/company-url';
import { Env, ENV_TOKEN } from '@edgvr-front/edgvr-portal/feature/env';

@Component({
  selector: 'edgvr-front-portal-feature-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ENV_TOKEN) private readonly env: Env,
    private readonly accountFacade: AccountFacade,
    private readonly companyUrlService: CompanyUrlService
  ) {}

  get company() {
    return this.companyUrlService.companyName(
      this.document.location.toString()
    );
  }

  get installer() {
    return this.env.binaries.installer;
  }

  get moduleBuilder() {
    return this.env.binaries.moduleBuilder;
  }

  get frontUrl() {
    return this.env.frontUrl;
  }

  get contact() {
    return this.env.contact;
  }

  onSignOut() {
    return this.accountFacade.signOut();
  }
}
