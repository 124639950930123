import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NotificationEntity } from '@edgvr-front/notifications/domain';

@Component({
  selector: 'edgvr-front-ui-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  @Input() notifications!: NotificationEntity[];

  @Output() closeNotification = new EventEmitter<NotificationEntity>();

  onCloseNotification(notification: NotificationEntity) {
    this.closeNotification.emit(notification);
  }
}
