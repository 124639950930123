import { createAction, props } from '@ngrx/store';
import {
  Account,
  AccountId,
  CreateAccount,
  UpdateAccount,
} from '@edgvr-front/accounts/domain';

export const loadAdmins = createAction('[Admins/API] Load Admins');
export const loadAdminsSuccess = createAction(
  '[Admins/API] Load Admins Success',
  props<{ admins: Account[] }>()
);
export const loadAdminsFailure = createAction(
  '[Admins/API] Load Admins Failure',
  props<{ error: string }>()
);

export const createAdmin = createAction(
  '[Admins/API] Create Admin',
  props<{ createAdmin: CreateAccount }>()
);
export const createAdminSuccess = createAction(
  '[Admins/API] Create Admin Success',
  props<{ admin: Account }>()
);
export const createAdminFailure = createAction(
  '[Admins/API] Create Admin Failure',
  props<{ error: string }>()
);

export const updateAdmin = createAction(
  '[Admins/API] Update Admin',
  props<{ admin: Account; update: UpdateAccount }>()
);
export const updateAdminSuccess = createAction(
  '[Admins/API] Update Admin Success',
  props<{ admin: Account }>()
);
export const updateAdminFailure = createAction(
  '[Admins/API] Update Admin Failure',
  props<{ error: string }>()
);

export const deleteAdmin = createAction(
  '[Admins/API] Delete Admin',
  props<{ adminId: AccountId }>()
);
export const deleteAdminSuccess = createAction(
  '[Admins/API] Delete Admin Success',
  props<{ adminId: AccountId }>()
);
export const deleteAdminFailure = createAction(
  '[Admins/API] Delete Admin Failure',
  props<{ error: string }>()
);

export const resetPasswordAdmin = createAction(
  '[Admins/API] Reset Password Admin',
  props<{ account: Account }>()
);
export const resetPasswordAdminSuccess = createAction(
  '[Admins/API] Reset Password Admin Success',
  props<{ account: Account }>()
);
export const resetPasswordAdminFailure = createAction(
  '[Admins/API] Reset Password Admin Failure',
  props<{ error: string }>()
);
