import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsDataAccessModule } from '@edgvr-front/notifications/data-access';
import { NotificationsUiModule } from '@edgvr-front/notifications/ui';
import { UtilCommonModule } from '@edgvr-front/util/common';
import Components from './components';

@NgModule({
  imports: [
    CommonModule,
    NotificationsDataAccessModule,
    NotificationsUiModule,
    UtilCommonModule,
  ],
  declarations: [...Components],
  exports: [...Components],
})
export class NotificationsFeatureNotificationsModule {}
