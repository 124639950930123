import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { CompanyAccessService } from '../../services';

@Directive({
  selector: '[edgvrFrontCompanyAccess]',
})
export class CompanyAccessDirective implements OnInit {
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly companyAccessService: CompanyAccessService
  ) {}

  ngOnInit() {
    this.process(this.companyAccessService.isCompany());
  }

  private process(isRender: boolean) {
    if (isRender) {
      // https://github.com/angular/angular/issues/12243
      return this.viewContainer
        .createEmbeddedView(this.templateRef)
        .detectChanges();
    }

    return this.viewContainer.clear();
  }
}
