<header class="dark">
  <a [routerLink]="['/']" class="logo logo-with-text me-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="31"
      fill="none"
      viewBox="0 0 120 31"
    >
      <path
        fill="#fff"
        d="M13.988 15.496c0-4.008-2.946-7.272-7.018-7.272-3.904 0-6.97 3.288-6.97 7.152 0 3.888 3.162 7.056 7.042 7.056 2.994 0 5.341-1.944 6.491-4.608H9.461c-.646.816-1.341 1.248-2.419 1.248-1.557 0-2.874-.936-3.114-2.52h9.965c.071-.36.095-.696.095-1.056Zm-3.976-1.632H3.976c.312-1.44 1.581-2.28 3.018-2.28 1.437 0 2.707.84 3.018 2.28ZM30.048 22V1.24h-3.976v8.568c-1.078-1.104-2.371-1.584-3.904-1.584-4 0-6.587 3.216-6.587 7.08 0 3.936 2.587 7.128 6.659 7.128 1.557 0 3.138-.456 3.976-1.872h.048V22h3.784Zm-3.76-6.72c0 1.968-1.413 3.432-3.402 3.432-1.988 0-3.329-1.488-3.329-3.432s1.461-3.336 3.377-3.336c1.916 0 3.354 1.416 3.354 3.336ZM46.701 20.392V8.68h-3.976v1.248h-.048c-.766-1.224-2.3-1.68-3.665-1.68-4.048 0-6.706 3.264-6.706 7.176 0 3.888 2.73 7.008 6.706 7.008 1.414 0 2.947-.6 3.641-1.896h.048v.408c0 2.112-1.054 3.624-3.233 3.624-.983 0-1.893-.36-2.324-1.32h-4.599c.096.744.383 1.44.814 2.064 1.318 1.92 3.57 2.616 5.821 2.616 2.42 0 5.413-.912 6.73-3.144.767-1.32.791-2.904.791-4.392ZM42.725 15.4c0 1.92-1.341 3.312-3.258 3.312-1.916 0-3.185-1.584-3.185-3.432 0-1.8 1.365-3.312 3.21-3.312 1.964 0 3.233 1.512 3.233 3.432Z"
      />
      <path
        fill="#E6332A"
        d="M63.447 9.26c0-1.976-.754-3.822-2.262-5.096-1.586-1.326-3.952-1.404-5.928-1.404h-6.292V22h4.706v-6.032h2.132c2.158 0 3.952-.208 5.616-1.664 1.352-1.196 2.028-3.276 2.028-5.044Zm-4.706.078c0 1.95-1.456 2.314-3.068 2.314H53.67V7.076h1.82c1.612 0 3.25.26 3.25 2.262Zm19.895 5.434c0-4.42-3.354-7.67-7.774-7.67s-7.774 3.25-7.774 7.67 3.354 7.67 7.774 7.67 7.774-3.25 7.774-7.67Zm-4.316 0c0 1.95-1.456 3.64-3.458 3.64-2.002 0-3.458-1.69-3.458-3.64 0-1.95 1.456-3.64 3.458-3.64 2.002 0 3.458 1.69 3.458 3.64Zm13.417-3.354V7.076c-1.664 0-2.86.546-3.614 2.028h-.052V7.57h-4.056V22h4.316v-7.93c0-2.236 1.508-2.6 3.406-2.652Zm2.075-.572v6.734c0 3.51 2.496 4.42 5.512 4.42h.624v-3.25c-1.56 0-1.82-.312-1.82-1.924v-5.98h2.08V7.57h-2.08V2.76h-4.316v4.81H87.68v3.276h2.132ZM111.806 22V7.57h-4.316v1.534h-.052c-.858-1.43-2.626-2.028-4.212-2.028-4.342 0-7.254 3.484-7.254 7.696 0 4.29 2.808 7.722 7.254 7.722 1.56 0 3.432-.572 4.212-2.054h.052V22h4.316Zm-4.316-7.228c0 2.08-1.508 3.692-3.614 3.692-2.028 0-3.588-1.638-3.588-3.64 0-2.054 1.456-3.718 3.562-3.718s3.64 1.56 3.64 3.666ZM118.358 22V2.76h-4.316V22h4.316Z"
      />
    </svg>
    <span class="sub">{{ company }}</span>
  </a>
  <nav>
    <ul>
      <ng-container *edgvrFrontAdminCompanyAccess>
        <ng-container *edgvrFrontAccountCompanyAccess>
          <li>
            <a [routerLink]="['/', 'accounts']" routerLinkActive="active"
              >Accounts</a
            >
          </li>
          <li>
            <a [routerLink]="['/', 'admins']" routerLinkActive="active"
              >Admins</a
            >
          </li>
          <li>
            <a [routerLink]="['/', 'workshops']" routerLinkActive="active"
              >Workshops</a
            >
          </li>
          <li>
            <a [routerLink]="['/', 'modules']" routerLinkActive="active"
              >Modules</a
            >
          </li>
        </ng-container>
      </ng-container>

      <ng-container *edgvrFrontCompanyAccess>
        <ng-container *edgvrFrontAccountCompanyAdminAccess>
          <li>
            <a [routerLink]="['/', 'users']" routerLinkActive="active">Users</a>
          </li>
          <li>
            <a [routerLink]="['/', 'account']" routerLinkActive="active"
              >Account</a
            >
          </li>
        </ng-container>
      </ng-container>

      <ng-container *edgvrFrontAccountAccess>
        <li>
          <a [routerLink]="['/', 'profile']" routerLinkActive="active"
            >Profile</a
          >
        </li>
      </ng-container>

      <li>
        <a [href]="frontUrl" target="_blank">EdgPro.com</a>
      </li>
    </ul>
  </nav>
  <div class="ms-auto">
    <a
      *edgvrFrontAccountAccess
      class="btn btn-alt"
      (click)="onSignOut(); (false)"
      >Sign out</a
    >
    <div class="install-wrap">
      <a [href]="installer" target="_blank" *edgvrFrontAccountAccess>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          fill="none"
          viewBox="0 0 56 56"
        >
          <g filter="url(#filter0_d_1178_4085)">
            <path
              fill="#fff"
              d="M8 12.667A6.667 6.667 0 0 1 14.667 6h26.666A6.667 6.667 0 0 1 48 12.667v26.666A6.667 6.667 0 0 1 41.333 46H14.667A6.667 6.667 0 0 1 8 39.333V12.667Z"
            />
          </g>
          <rect
            width="30.833"
            height="9.583"
            x="12.583"
            y="31.833"
            fill="#E6332A"
            rx="2"
          />
          <path
            fill="#E6332A"
            stroke="#fff"
            d="M33.604 26.129a.27.27 0 0 0-.247-.183h-2.938v-2.359c0-.163-.12-.295-.268-.295h-4.29c-.148 0-.269.132-.269.295v2.359h-2.949a.27.27 0 0 0-.248.182.319.319 0 0 0 .058.323l5.35 5.92c.05.056.118.087.19.087.07 0 .139-.031.189-.086l5.365-5.92a.318.318 0 0 0 .057-.323Z"
          />
          <path
            fill="#3A3A3A"
            d="M17.61 15.91c0-1.438-1.014-2.61-2.417-2.61-1.345 0-2.401 1.18-2.401 2.568 0 1.396 1.089 2.533 2.426 2.533 1.031 0 1.84-.698 2.236-1.654h-1.403c-.223.293-.462.448-.833.448-.537 0-.99-.336-1.073-.905h3.433c.025-.13.033-.25.033-.38Zm-1.369-.585h-2.08c.108-.517.545-.819 1.04-.819s.933.302 1.04.819ZM23.144 18.246v-7.454h-1.37v3.076c-.371-.396-.817-.569-1.345-.569-1.378 0-2.27 1.155-2.27 2.543 0 1.413.892 2.56 2.295 2.56.536 0 1.08-.164 1.37-.673h.016v.517h1.304Zm-1.296-2.413c0 .707-.487 1.233-1.172 1.233-.684 0-1.147-.535-1.147-1.233 0-.698.504-1.198 1.164-1.198.66 0 1.155.509 1.155 1.198ZM28.881 17.669v-4.206h-1.37v.448h-.016c-.264-.44-.793-.603-1.263-.603-1.395 0-2.31 1.172-2.31 2.577 0 1.396.94 2.516 2.31 2.516.487 0 1.015-.215 1.254-.68h.017v.146c0 .758-.363 1.301-1.114 1.301-.338 0-.652-.129-.8-.474h-1.585c.033.268.132.518.28.742.454.689 1.23.939 2.006.939.833 0 1.865-.328 2.319-1.129.264-.474.272-1.043.272-1.577Zm-1.37-1.793c0 .69-.462 1.19-1.122 1.19-.66 0-1.098-.57-1.098-1.233 0-.646.47-1.19 1.106-1.19.677 0 1.114.544 1.114 1.233Z"
          />
          <path
            fill="#E6332A"
            d="M34.782 13.671c0-.709-.259-1.372-.777-1.83-.546-.476-1.36-.504-2.039-.504h-2.163v6.91h1.618V16.08h.733c.742 0 1.359-.074 1.931-.597.465-.43.697-1.177.697-1.812Zm-1.618.029c0 .7-.5.83-1.055.83h-.688v-1.643h.626c.554 0 1.117.094 1.117.813ZM37.712 14.446v-1.559c-.572 0-.983.196-1.242.729h-.018v-.551h-1.395v5.181h1.484V15.4c0-.803.519-.934 1.171-.953ZM43 15.65c0-1.586-1.153-2.753-2.673-2.753s-2.673 1.167-2.673 2.754 1.153 2.754 2.673 2.754S43 17.238 43 15.651Zm-1.484 0c0 .701-.5 1.308-1.189 1.308-.688 0-1.189-.607-1.189-1.307s.5-1.307 1.189-1.307c.688 0 1.189.607 1.189 1.307Z"
          />
          <path
            fill="#fff"
            d="M16.886 38.917h-1.18v-4.625h1.18v4.625ZM21.75 38.917h-1.025l-1.762-2.813-.013.007.032 2.806H17.85v-4.625h1.02l1.787 2.906.012-.013-.05-2.893h1.131v4.625ZM22.455 37.386h1.175c0 .374.263.63.644.63.387 0 .619-.193.619-.443 0-.406-.513-.481-1.119-.706-.663-.244-1.244-.575-1.244-1.369 0-.894.831-1.294 1.775-1.294.869 0 1.669.394 1.713 1.438h-1.169c-.044-.288-.212-.45-.594-.45-.35 0-.55.106-.55.343 0 .32.45.438.963.62.893.312 1.393.668 1.393 1.487 0 .912-.668 1.362-1.774 1.362-1.1 0-1.832-.462-1.832-1.468v-.15ZM29.405 35.33h-.906v3.587h-1.181v-3.588h-.913v-1.037h3v1.037ZM34.109 38.917h-1.281l-.225-.669h-1.607l-.243.669h-1.269l1.856-4.625h.932l1.837 4.625ZM32.29 37.31l-.475-1.58h-.018l-.476 1.58h.97ZM37.289 38.917h-2.55v-4.625h1.181v3.587h1.369v1.038ZM40.46 38.917h-2.55v-4.625h1.18v3.587h1.37v1.038Z"
          />
          <defs>
            <filter
              id="filter0_d_1178_4085"
              width="56"
              height="56"
              x="0"
              y="0"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1178_4085"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_1178_4085"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </a>
      <a
        [href]="moduleBuilder"
        target="_blank"
        *edgvrFrontAccountAdminCompanyAccess
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          fill="none"
          viewBox="0 0 56 56"
        >
          <g filter="url(#filter0_d_1178_4150)">
            <path
              fill="#fff"
              d="M8 12.667A6.667 6.667 0 0 1 14.667 6h26.666A6.667 6.667 0 0 1 48 12.667v26.666A6.667 6.667 0 0 1 41.333 46H14.667A6.667 6.667 0 0 1 8 39.333V12.667Z"
            />
          </g>
          <rect
            width="30.833"
            height="9.583"
            x="12.583"
            y="31.833"
            fill="#E6332A"
            rx="2"
          />
          <path
            fill="#fff"
            d="M19.932 37.65c0 .354-.132.732-.408.966-.456.39-.876.384-1.452.384h-1.41v-4.44h1.368c.534 0 1.038.006 1.422.432.186.204.264.456.264.732 0 .378-.186.708-.516.894.486.168.732.528.732 1.032Zm-1.302-1.758c0-.282-.21-.336-.444-.336h-.438v.69h.432c.246 0 .45-.072.45-.354Zm.216 1.686c0-.318-.264-.384-.528-.384h-.57v.81h.564c.282 0 .534-.096.534-.426Zm5.096-.336c0 .528-.06.978-.444 1.362-.335.342-.768.48-1.242.48-.462 0-.954-.132-1.277-.474-.402-.426-.444-.804-.444-1.368V34.56h1.134v2.682c0 .402.066.846.575.846.535 0 .564-.426.564-.846V34.56h1.134v2.682ZM25.87 39h-1.134v-4.44h1.134V39Zm3.283 0h-2.448v-4.44h1.134v3.444h1.314V39Zm4.226-2.232c0 1.458-.834 2.232-2.286 2.232H29.54v-4.44h1.47c.804 0 1.458.132 1.962.828.288.402.408.888.408 1.38Zm-1.134.018c0-.708-.324-1.23-1.08-1.23h-.492v2.448h.486c.774 0 1.086-.498 1.086-1.218ZM36.518 39h-2.466v-4.44h2.466v.996h-1.38v.69h1.338v.996h-1.338v.762h1.38V39Zm4.124 0h-1.296l-1.026-1.434h-.012V39h-1.086v-4.44h1.404c.468 0 .984.012 1.368.318.366.294.546.75.546 1.212 0 .684-.402 1.284-1.104 1.398L40.642 39Zm-1.188-2.844c0-.438-.342-.6-.732-.6h-.414v1.236h.402c.426 0 .744-.18.744-.636Z"
          />
          <path
            fill="#E6332A"
            stroke="#fff"
            d="M33.604 26.129a.27.27 0 0 0-.247-.183h-2.938v-2.359c0-.163-.12-.295-.268-.295h-4.29c-.148 0-.269.132-.269.295v2.359h-2.949a.27.27 0 0 0-.248.182.319.319 0 0 0 .058.323l5.35 5.92c.05.056.118.087.19.087.07 0 .139-.031.189-.086l5.365-5.92a.318.318 0 0 0 .057-.323Z"
          />
          <path
            fill="#3A3A3A"
            d="M17.61 15.91c0-1.438-1.014-2.61-2.417-2.61-1.345 0-2.401 1.18-2.401 2.568 0 1.396 1.089 2.533 2.426 2.533 1.031 0 1.84-.698 2.236-1.654h-1.403c-.222.293-.462.448-.833.448-.537 0-.99-.336-1.073-.905h3.433c.025-.13.033-.25.033-.38Zm-1.369-.585h-2.08c.108-.517.545-.819 1.04-.819s.933.302 1.04.819ZM23.144 18.246v-7.454h-1.37v3.076c-.371-.396-.817-.569-1.345-.569-1.378 0-2.27 1.155-2.27 2.543 0 1.413.892 2.56 2.295 2.56.536 0 1.08-.164 1.37-.673h.016v.517h1.304Zm-1.296-2.413c0 .707-.487 1.233-1.172 1.233-.684 0-1.147-.535-1.147-1.233 0-.698.504-1.198 1.164-1.198.66 0 1.155.509 1.155 1.198ZM28.881 17.669v-4.206h-1.37v.448h-.016c-.264-.44-.793-.603-1.263-.603-1.395 0-2.31 1.172-2.31 2.577 0 1.396.94 2.516 2.31 2.516.487 0 1.015-.215 1.254-.68h.017v.146c0 .758-.363 1.301-1.114 1.301-.338 0-.652-.129-.8-.474h-1.585c.033.268.132.518.28.742.454.689 1.23.939 2.006.939.833 0 1.865-.328 2.319-1.129.264-.474.272-1.043.272-1.577Zm-1.37-1.793c0 .69-.462 1.19-1.122 1.19-.66 0-1.098-.57-1.098-1.233 0-.646.47-1.19 1.106-1.19.677 0 1.114.544 1.114 1.233Z"
          />
          <path
            fill="#E6332A"
            d="M34.782 13.671c0-.709-.259-1.372-.777-1.83-.546-.476-1.36-.504-2.039-.504h-2.163v6.91h1.618V16.08h.733c.742 0 1.36-.074 1.931-.597.465-.43.697-1.177.697-1.812Zm-1.618.029c0 .7-.5.83-1.055.83h-.688v-1.643h.626c.554 0 1.117.094 1.117.813ZM37.712 14.446v-1.559c-.572 0-.983.196-1.242.729h-.018v-.551h-1.395v5.181h1.484V15.4c0-.803.519-.934 1.171-.953ZM43 15.65c0-1.586-1.153-2.753-2.673-2.753s-2.673 1.167-2.673 2.754 1.153 2.754 2.673 2.754S43 17.238 43 15.651Zm-1.484 0c0 .701-.5 1.308-1.189 1.308-.688 0-1.189-.607-1.189-1.307s.5-1.307 1.189-1.307c.688 0 1.189.607 1.189 1.307Z"
          />
          <defs>
            <filter
              id="filter0_d_1178_4150"
              width="56"
              height="56"
              x="0"
              y="0"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1178_4150"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_1178_4150"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </a>
    </div>
    <a [href]="'mailto:' + contact" class="btn">Contact</a>
  </div>
</header>
