import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { API_CONFIG_TOKEN, IConfig } from './api-data-access.tokens';
import { ApiService } from './services';
import { HeadersAPIInterceptor } from './interceptors';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [ApiService],
})
export class ApiDataAccessModule {
  static forRoot(config: IConfig): ModuleWithProviders<ApiDataAccessModule> {
    return {
      ngModule: ApiDataAccessModule,
      providers: [
        ApiService,
        {
          provide: API_CONFIG_TOKEN,
          useValue: config,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeadersAPIInterceptor,
          multi: true,
        },
      ],
    };
  }
}
