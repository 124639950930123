import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CompanyName } from '@edgvr-front/companies/domain';
import { CompanyActions } from '../actions';
import { CompanySelectors } from '../selectors';

@Injectable()
export class CompanyFacade {
  company$ = this.store.pipe(select(CompanySelectors.company));
  isLoading$ = this.store.pipe(select(CompanySelectors.isLoading));

  constructor(private readonly store: Store) {}

  loadCompany(companyName?: CompanyName) {
    this.store.dispatch(
      CompanyActions.loadCompany({
        companyName,
      })
    );
  }
}
