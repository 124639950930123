import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { Account } from '@edgvr-front/accounts/domain';
import { AdminsActions } from '../actions';

export const ADMINS_FEATURE_KEY = 'admins';

export interface AdminsState extends EntityState<Account> {
  isLoading: boolean;
}

export const adminsAdapter: EntityAdapter<Account> =
  createEntityAdapter<Account>({
    selectId: (_) => _.id,
    sortComparer: (lhs, rhs) => rhs.id - lhs.id,
  });

export const initialAdminsState: AdminsState = adminsAdapter.getInitialState({
  isLoading: false,
});

const reducer = createReducer(
  initialAdminsState,
  on(AdminsActions.loadAdmins, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AdminsActions.loadAdminsSuccess, (state, { admins }) =>
    adminsAdapter.setAll(admins, {
      ...state,
      isLoading: false,
    })
  ),
  on(AdminsActions.loadAdminsFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AdminsActions.createAdmin, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AdminsActions.createAdminSuccess, (state, { admin }) =>
    adminsAdapter.setOne(admin, {
      ...state,
      isLoading: false,
    })
  ),
  on(AdminsActions.createAdminFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AdminsActions.updateAdmin, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AdminsActions.updateAdminSuccess, (state, { admin }) =>
    adminsAdapter.setOne(admin, {
      ...state,
      isLoading: false,
    })
  ),
  on(AdminsActions.updateAdminFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AdminsActions.deleteAdmin, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AdminsActions.deleteAdminSuccess, (state, { adminId }) =>
    adminsAdapter.removeOne(adminId, {
      ...state,
      isLoading: false,
    })
  ),
  on(AdminsActions.deleteAdminFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AdminsActions.resetPasswordAdmin, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    AdminsActions.resetPasswordAdminFailure,
    AdminsActions.resetPasswordAdminSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  )
);

export function adminsReducer(state: AdminsState | undefined, action: Action) {
  return reducer(state, action);
}
