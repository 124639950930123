import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { CompanyActions } from '../actions';
import { CompanyService } from '../services';

@Injectable()
export class CompanyEffects {
  loadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadCompany),
      fetch({
        run: (action) => {
          return this.companyService.loadCompany(action.companyName).pipe(
            map((_) =>
              CompanyActions.loadCompanySuccess({
                company: _,
              })
            )
          );
        },
        onError: (action, error) =>
          CompanyActions.loadCompanyFailure({
            error: error.message,
          }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly companyService: CompanyService
  ) {}
}
