import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COMPANY_FEATURE_KEY, CompanyState } from '../reducers';

export const companyState =
  createFeatureSelector<CompanyState>(COMPANY_FEATURE_KEY);

export const company = createSelector(
  companyState,
  (state: CompanyState) => state.company
);

export const isLoading = createSelector(
  companyState,
  (state: CompanyState) => state.isLoading
);
