import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { ExposedHeaders } from '@edgvr-front/api/domain';
import { TokenService } from '@edgvr-front/token/data-access';
import { ApiActions } from '../../actions';

@Injectable()
export class HeadersAPIInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store,
    private readonly tokenService: TokenService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenService.token;

    let headers = new HttpHeaders();

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    const auth = req.clone({
      headers,
    });

    return next.handle(auth).pipe(
      tap((_) => this.onHttpEvent(_)),
      catchError((_) => this.onError(_))
    );
  }

  private onHttpEvent(httpEvent: HttpEvent<unknown>) {
    if (httpEvent instanceof HttpResponse) {
      return this.onResponse(httpEvent);
    }
  }

  private onResponse(response: HttpResponse<unknown>) {
    const accountToken = response.headers.get(ExposedHeaders.AccountToken);

    if (accountToken) {
      this.store.dispatch(
        ApiActions.edgVrHeader({
          key: ExposedHeaders.AccountToken,
          value: accountToken,
        })
      );
    }
  }

  private onError(error: HttpErrorResponse) {
    if (error.error.code === 1401 || error.error.code === 1403) {
      this.store.dispatch(ApiActions.noAuthorized());
    } else {
      this.store.dispatch(
        ApiActions.error({
          code: error.error.code || 1500,
          message: error.error.message || error.message || 'Unknown error',
          errorId: error.error.errorId,
        })
      );
    }

    return throwError(() => error);
  }
}
