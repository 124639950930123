import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { timer } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CompaniesActions } from '../actions';
import { CompaniesService } from '../services';

@Injectable()
export class CompaniesEffects {
  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.loadCompanies),
      fetch({
        run: () => {
          return this.companiesService
            .loadCompanies()
            .pipe(
              map((_) =>
                CompaniesActions.loadCompaniesSuccess({ companies: _ })
              )
            );
        },
        onError: (action, error) =>
          CompaniesActions.loadCompaniesFailure({ error: error.message }),
      })
    )
  );

  selectCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.selectCompany),
      mergeMap((_) => timer(0).pipe(map(() => _))),
      fetch({
        run: (action) =>
          CompaniesActions.selectCompanySuccess({ id: action.id }),
      })
    )
  );

  unSelectCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.unSelectCompany),
      mergeMap((_) => timer(0).pipe(map(() => _))),
      fetch({
        run: () => CompaniesActions.unSelectCompanySuccess(),
      })
    )
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.createCompany),
      fetch({
        run: (action) =>
          this.companiesService
            .createCompany(action.createCompany)
            .pipe(
              map((_) => CompaniesActions.createCompanySuccess({ company: _ }))
            ),
        onError: (action, error) =>
          CompaniesActions.createCompanyFailure({ error: error.message }),
      })
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.updateCompany),
      fetch({
        run: (action) =>
          this.companiesService
            .updateCompany(action.update)
            .pipe(
              map((_) => CompaniesActions.updateCompanySuccess({ company: _ }))
            ),
        onError: (action, error) =>
          CompaniesActions.updateCompanyFailure({
            company: action.company,
            error: error.message,
          }),
      })
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.deleteCompany),
      fetch({
        run: (action) =>
          this.companiesService.deleteCompany(action.companyId).pipe(
            map((_) =>
              CompaniesActions.deleteCompanySuccess({
                companyId: action.companyId,
              })
            )
          ),
        onError: (action, error) =>
          CompaniesActions.deleteCompanyFailure({
            error: error.message,
          }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly companiesService: CompaniesService
  ) {}
}
