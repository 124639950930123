import { createAction, props } from '@ngrx/store';

export const edgVrHeader = createAction(
  '[API] EdgVr Header',
  props<{ key: string; value: string }>()
);
export const noAuthorized = createAction('[API] No Authorized');
export const error = createAction(
  '[API] Error',
  props<{ code: number; message: string; errorId?: string }>()
);
