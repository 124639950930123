import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { Company, CompanyId } from '@edgvr-front/companies/domain';
import { CompaniesActions } from '../actions';

export const COMPANIES_FEATURE_KEY = 'companies';

export interface CompaniesState extends EntityState<Company> {
  isLoading: boolean;
  isUpdating: boolean;
  selectedCompanyId: null | CompanyId;
}

export const companiesAdapter: EntityAdapter<Company> =
  createEntityAdapter<Company>({
    sortComparer: (lhs: Company, rhs: Company) =>
      lhs.name.localeCompare(rhs.name),
    selectId: (_) => _.id,
  });

export const initialCompaniesState: CompaniesState =
  companiesAdapter.getInitialState({
    isLoading: false,
    isUpdating: false,
    selectedCompanyId: null,
  });

const reducer = createReducer(
  initialCompaniesState,
  on(CompaniesActions.loadCompanies, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CompaniesActions.loadCompaniesSuccess, (state, { companies }) =>
    companiesAdapter.setAll(companies, { ...state, isLoading: false })
  ),
  on(CompaniesActions.loadCompaniesFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(CompaniesActions.selectCompanySuccess, (state, { id }) => {
    return {
      ...state,
      selectedCompanyId: id,
    };
  }),
  on(CompaniesActions.unSelectCompanySuccess, (state) => ({
    ...state,
    selectedCompanyId: null,
  })),
  on(CompaniesActions.createCompany, (state) => ({
    ...state,
    isUpdating: true,
  })),
  on(CompaniesActions.createCompanySuccess, (state, { company }) =>
    companiesAdapter.setOne(company, { ...state, isUpdating: false })
  ),
  on(CompaniesActions.createCompanyFailure, (state) => ({
    ...state,
    isUpdating: false,
  })),
  on(CompaniesActions.updateCompany, (state, { update, company }) =>
    companiesAdapter.updateOne(
      {
        id: update.id,
        changes: {
          ...update,
          image: update.deleteImage ? undefined : update.image || company.image,
        },
      },
      { ...state, isUpdating: true }
    )
  ),
  on(CompaniesActions.updateCompanySuccess, (state, { company }) =>
    companiesAdapter.setOne(company, { ...state, isUpdating: false })
  ),
  on(CompaniesActions.updateCompanyFailure, (state, { company }) =>
    companiesAdapter.setOne(company, { ...state, isUpdating: false })
  ),
  on(CompaniesActions.deleteCompany, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CompaniesActions.deleteCompanySuccess, (state, { companyId }) =>
    companiesAdapter.removeOne(companyId, { ...state, isLoading: false })
  ),
  on(CompaniesActions.deleteCompanyFailure, (state) => ({
    ...state,
    isLoading: false,
  }))
);

export function companiesReducer(
  state: CompaniesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
