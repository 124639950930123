import { createAction, props } from '@ngrx/store';
import {
  Company,
  CompanyId,
  CreateCompany,
  UpdateCompany,
} from '@edgvr-front/companies/domain';

export const loadCompanies = createAction('[Companies/API] Load Companies');
export const loadCompaniesSuccess = createAction(
  '[Companies/API] Load Companies Success',
  props<{ companies: Company[] }>()
);
export const loadCompaniesFailure = createAction(
  '[Companies/API] Load Companies Failure',
  props<{ error: string }>()
);

export const selectCompany = createAction(
  '[Companies/API] Select Company',
  props<{ id: CompanyId }>()
);
export const selectCompanySuccess = createAction(
  '[Companies/API] Select Company Success',
  props<{ id: CompanyId }>()
);

export const unSelectCompany = createAction('[Companies/API] Unselect Company');
export const unSelectCompanySuccess = createAction(
  '[Companies/API] Unselect Company Success'
);

export const createCompany = createAction(
  '[Companies/API] Create Company',
  props<{ createCompany: CreateCompany }>()
);
export const createCompanySuccess = createAction(
  '[Companies/API] Create Company Success',
  props<{ company: Company }>()
);
export const createCompanyFailure = createAction(
  '[Companies/API] Create Company Failure',
  props<{ error: string }>()
);

export const updateCompany = createAction(
  '[Companies/API] Update Company',
  props<{ company: Company; update: UpdateCompany }>()
);
export const updateCompanySuccess = createAction(
  '[Companies/API] Update Company Success',
  props<{ company: Company }>()
);
export const updateCompanyFailure = createAction(
  '[Companies/API] Update Company Failure',
  props<{ company: Company; error: string }>()
);

export const deleteCompany = createAction(
  '[Companies/API] Delete Company',
  props<{ companyId: CompanyId }>()
);
export const deleteCompanySuccess = createAction(
  '[Companies/API] Delete Company Success',
  props<{ companyId: CompanyId }>()
);
export const deleteCompanyFailure = createAction(
  '[Companies/API] Delete Company Failure',
  props<{ error: string }>()
);
