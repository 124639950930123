import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  Account,
  AccountId,
  CreateAccount,
  UpdateAccount,
} from '@edgvr-front/accounts/domain';
import { ApiService } from '@edgvr-front/api/data-access';

@Injectable()
export class AdminsService {
  constructor(private readonly apiService: ApiService) {}

  listAdmins(): Observable<Account[]> {
    return this.apiService
      .get<{ admins: Account[] }>('admins')
      .pipe(map((_) => _.admins));
  }

  createAdmin(createAdmin: CreateAccount) {
    return this.apiService
      .post<{ admin: Account }>('admins', {
        ...createAdmin,
      })
      .pipe(map((_) => _.admin));
  }

  updateAdmin(updateAdmin: UpdateAccount) {
    return this.apiService
      .put<{ admin: Account }>(`admins/${updateAdmin.id}`, {
        ...updateAdmin,
      })
      .pipe(map((_) => _.admin));
  }

  deleteAdmin(accountId: AccountId) {
    return this.apiService.delete<void>(`admins/${accountId}`);
  }

  resetPasswordAdmin(account: Account) {
    return this.apiService.put<void>(`admins/${account.id}/reset-password`, {});
  }
}
