import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import Pipes from './pipes';
import Services from './services';

@NgModule({
  declarations: [...Pipes],
  imports: [CommonModule],
  exports: [...Pipes],
  providers: [...Services],
})
export class CompaniesUtilCompanyUrlModule {}
