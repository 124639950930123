import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  accountCompanyAccessGuard,
  accountCompanyAdminAccessGuard,
} from '@edgvr-front/account/feature/account-access';
import { HeaderTemplateComponent } from '@edgvr-front/edgvr-portal/feature/template';
import {
  adminCompanyAccessGuard,
  companyAccessGuard,
} from '@edgvr-front/companies/feature/company-access';

const routes: Routes = [
  {
    path: 'profile',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@edgvr-front/edgvr-portal/feature/profile').then(
            (m) => m.EdgvrPortalFeatureProfileModule
          ),
      },
    ],
    canActivate: [accountCompanyAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'account',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@edgvr-front/edgvr-portal/feature/account').then(
            (m) => m.EdgvrPortalFeatureAccountModule
          ),
      },
    ],
    canActivate: [companyAccessGuard, accountCompanyAdminAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'accounts',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@edgvr-front/edgvr-portal/feature/accounts').then(
            (m) => m.EdgvrPortalFeatureAccountsModule
          ),
      },
    ],
    canActivate: [adminCompanyAccessGuard, accountCompanyAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admins',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@edgvr-front/edgvr-portal/feature/admins').then(
            (m) => m.EdgvrPortalFeatureAdminsModule
          ),
      },
    ],
    canActivate: [adminCompanyAccessGuard, accountCompanyAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'users',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@edgvr-front/edgvr-portal/feature/users').then(
            (m) => m.EdgvrPortalFeatureUsersModule
          ),
      },
    ],
    canActivate: [companyAccessGuard, accountCompanyAdminAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'workshops',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@edgvr-front/edgvr-portal/feature/workshops').then(
            (m) => m.EdgvrPortalFeatureWorkshopsModule
          ),
      },
    ],
    canActivate: [adminCompanyAccessGuard, accountCompanyAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'modules',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@edgvr-front/edgvr-portal/feature/modules').then(
            (m) => m.EdgvrPortalFeatureModulesModule
          ),
      },
    ],
    canActivate: [adminCompanyAccessGuard, accountCompanyAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '**',
    redirectTo: 'profile',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
