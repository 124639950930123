import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@edgvr-front/api/data-access';
import {
  Company,
  CompanyId,
  CreateCompany,
  UpdateCompany,
} from '@edgvr-front/companies/domain';

@Injectable()
export class CompaniesService {
  constructor(private readonly apiService: ApiService) {}

  loadCompanies() {
    return this.apiService
      .get<{ companies: Company[] }>('companies')
      .pipe(map((_) => _.companies));
  }

  createCompany(createCompany: CreateCompany) {
    const formData = new FormData();

    formData.set('name', createCompany.name.toLowerCase());

    if (createCompany.title != undefined) {
      formData.set('title', createCompany.title);
    }

    if (createCompany.address != undefined) {
      formData.set('address', createCompany.address);
    }

    if (createCompany.enabled) {
      formData.set('enabled', createCompany.enabled + '');
    }

    if (createCompany.image) {
      formData.set('image', createCompany.image);
    }

    createCompany.workshops.forEach((_) =>
      formData.append('workshopIds[]', _.id + '')
    );

    return this.apiService
      .post<{ company: Company }>('companies', formData)
      .pipe(map((_) => _.company));
  }

  updateCompany(updateCompany: UpdateCompany) {
    const formData = new FormData();

    formData.set('name', updateCompany.name);

    if (updateCompany.title != undefined) {
      formData.set('title', updateCompany.title);
    }

    if (updateCompany.address != undefined) {
      formData.set('address', updateCompany.address);
    }

    if (updateCompany.enabled) {
      formData.set('enabled', updateCompany.enabled + '');
    }

    if (updateCompany.image) {
      formData.set('image', updateCompany.image);
    }

    if (updateCompany.deleteImage) {
      formData.set('deleteImage', updateCompany.deleteImage + '');
    }

    updateCompany.workshops.forEach((_) =>
      formData.append('workshopIds[]', _.id + '')
    );

    return this.apiService
      .put<{ company: Company }>(`companies/${updateCompany.id}`, formData)
      .pipe(map((_) => _.company));
  }

  deleteCompany(companyId: CompanyId) {
    return this.apiService.delete<void>(`companies/${companyId}`);
  }
}
