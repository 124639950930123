import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Companies } from '@edgvr-front/companies/domain';
import { CompanyUrlService } from '@edgvr-front/companies/util/company-url';

@Injectable()
export class CompanyAccessService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly companyUrlService: CompanyUrlService
  ) {}

  isAdminCompany() {
    return this.companyName === Companies.Admin;
  }

  isCompany() {
    return this.companyName !== Companies.Admin;
  }

  private get companyName() {
    return this.companyUrlService.companyName(
      this.document.location.toString()
    );
  }
}
