import { Company, CompanyName } from '@edgvr-front/companies/domain';
import { createAction, props } from '@ngrx/store';

export const loadCompany = createAction(
  '[Companies/API] Load Company',
  props<{ companyName?: CompanyName }>()
);
export const loadCompanySuccess = createAction(
  '[Companies/API] Load Company Success',
  props<{ company: Company }>()
);
export const loadCompanyFailure = createAction(
  '[Companies/API] Load Company Failure',
  props<{ error: string }>()
);
