import { Pipe, PipeTransform } from '@angular/core';
import { CompanyName } from '@edgvr-front/companies/domain';
import { CompanyUrlService } from '../../services';

@Pipe({
  name: 'companyUrl',
})
export class CompanyUrlPipe implements PipeTransform {
  constructor(private readonly companyUrlService: CompanyUrlService) {}

  transform(companyName: CompanyName): string {
    return this.companyUrlService.companyUrl(companyName);
  }
}
