import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { SENTRY_ERROR_HANDLER_PARAMS_TOKEN } from './token';
import {
  IParams,
  SentryErrorHandler,
} from './handlers/sentry-error-handler.handler';

@NgModule({
  imports: [],
})
export class SentryFeatureModule {
  static forRoot(params: IParams): ModuleWithProviders<SentryFeatureModule> {
    return {
      ngModule: SentryFeatureModule,
      providers: [
        {
          provide: SENTRY_ERROR_HANDLER_PARAMS_TOKEN,
          useValue: params,
        },
        {
          provide: ErrorHandler,
          useClass: SentryErrorHandler,
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
      ],
    };
  }
}
