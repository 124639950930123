import { Injectable } from '@angular/core';
import {
  AccountJwt,
  ResetPassword,
  SignIn,
  UpdateMe,
  UpdatePassword,
} from '@edgvr-front/account/domain';
import { Account } from '@edgvr-front/accounts/domain';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountActions } from '../actions';
import { AccountSelectors } from '../selectors';
import { AccountService } from '../services';

@Injectable()
export class AccountFacade {
  account$ = this.store.pipe(select(AccountSelectors.account));
  isLoading$ = this.store.pipe(select(AccountSelectors.isLoading));

  constructor(
    private readonly store: Store,
    private readonly accountService: AccountService
  ) {}

  get accountJwt$() {
    return this.accountService.accountJwt$;
  }

  get companies$() {
    return this.accountService.accountJwt$.pipe(
      map((_) => _?.companies || null)
    );
  }

  me() {
    this.store.dispatch(AccountActions.me());
  }

  signIn(data: SignIn) {
    this.store.dispatch(
      AccountActions.signIn({ email: data.email, password: data.password })
    );
  }

  reSignIn() {
    this.store.dispatch(AccountActions.reSignIn());
  }

  signOut() {
    this.store.dispatch(AccountActions.signOut());
  }

  resetPassword(resetPassword: ResetPassword) {
    this.store.dispatch(AccountActions.resetPassword({ resetPassword }));
  }

  updatePassword(updatePassword: UpdatePassword) {
    this.store.dispatch(AccountActions.updatePassword({ updatePassword }));
  }

  updateMe(data: { account: Account; update: UpdateMe }) {
    this.store.dispatch(
      AccountActions.updateMe({ account: data.account, update: data.update })
    );
  }
}
