import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NOTIFICATIONS_FEATURE_KEY,
  NotificationsState,
  notificationsAdapter,
} from '../reducers';

export const notificationsState = createFeatureSelector<NotificationsState>(
  NOTIFICATIONS_FEATURE_KEY
);

const { selectAll } = notificationsAdapter.getSelectors();

export const notifications = createSelector(
  notificationsState,
  (state: NotificationsState) => selectAll(state)
);
