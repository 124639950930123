import { createAction, props } from '@ngrx/store';
import { Account, AccountId } from '@edgvr-front/accounts/domain';
import {
  CompanyAccount,
  CompanyAccountType,
  CompanyName,
  CreateCompanyAccount,
  UpdateCompanyAccount,
} from '@edgvr-front/companies/domain';

export const loadCompanyAccounts = createAction(
  '[Companies/API] Load Company Accounts',
  props<{ data?: { type?: CompanyAccountType; companyName?: CompanyName } }>()
);
export const loadCompanyAccountsSuccess = createAction(
  '[Companies/API] Load Company Accounts Success',
  props<{ accounts: CompanyAccount[] }>()
);
export const loadCompanyAccountsFailure = createAction(
  '[Companies/API] Load Company Accounts Failure',
  props<{ error: string }>()
);

export const selectCompanyAccount = createAction(
  '[Companies/API] Select Company Account',
  props<{ id: AccountId }>()
);
export const selectCompanyAccountSuccess = createAction(
  '[Companies/API] Select Company Account Success',
  props<{ id: AccountId }>()
);

export const unSelectCompanyAccount = createAction(
  '[Companies/API] Unselect Company Account'
);
export const unSelectCompanyAccountSuccess = createAction(
  '[Companies/API] Unselect Company Account Success'
);

export const createCompanyAccount = createAction(
  '[Companies/API] Create Company Account',
  props<{ data: CreateCompanyAccount; companyName?: CompanyName }>()
);
export const createCompanyAccountSuccess = createAction(
  '[Companies/API] Create Company Account Success',
  props<{ account: CompanyAccount }>()
);
export const createCompanyAccountFailure = createAction(
  '[Companies/API] Create Company Account Failure',
  props<{ error: string }>()
);

export const updateCompanyAccount = createAction(
  '[Companies/API] Update Company Account',
  props<{
    account: CompanyAccount;
    update: UpdateCompanyAccount;
    companyName?: CompanyName;
  }>()
);
export const updateCompanyAccountSuccess = createAction(
  '[Companies/API] Update Company Account Success',
  props<{ account: CompanyAccount }>()
);
export const updateCompanyAccountFailure = createAction(
  '[Companies/API] Update Company Account Failure',
  props<{ account: CompanyAccount; error: string }>()
);

export const deleteCompanyAccount = createAction(
  '[Companies/API] Delete Company Account',
  props<{
    accountId: AccountId;
    companyName?: CompanyName;
  }>()
);
export const deleteCompanyAccountSuccess = createAction(
  '[Companies/API] Delete Company Account Success',
  props<{ accountId: AccountId }>()
);
export const deleteCompanyAccountFailure = createAction(
  '[Companies/API] Delete Company Account Failure',
  props<{ error: string }>()
);

export const cleanCompanyAccounts = createAction(
  '[Companies/API] Clean Company Accounts'
);

export const resetPasswordCompanyAccount = createAction(
  '[Companies/API] Reset Password Company Account',
  props<{ account: Account; companyName?: CompanyName }>()
);
export const resetPasswordCompanyAccountSuccess = createAction(
  '[Admins/API] Reset Password Company Account Success',
  props<{ account: Account }>()
);
export const resetPasswordCompanyAccountFailure = createAction(
  '[Admins/API] Reset Password Company Account Failure',
  props<{ error: string }>()
);
