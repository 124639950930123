<div class="alerts-item-icon">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    *ngIf="!notification.code || notification.code === 200; else errorIcon"
  >
    <g fill="#56BD66" clip-path="url(#clip0_1414_4007)">
      <path
        d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.384 12-12S18.617 0 12 0Zm0 22.141C6.409 22.141 1.859 17.592 1.859 12S6.409 1.859 12 1.859c5.592 0 10.141 4.55 10.141 10.141 0 5.592-4.55 10.141-10.141 10.141Z"
      />
      <path
        d="M17.506 7.827a.93.93 0 0 0-1.313.063l-5.67 6.243-2.74-2.784a.928.928 0 1 0-1.325 1.303l3.43 3.485a.928.928 0 0 0 1.35-.026l6.33-6.971a.93.93 0 0 0-.063-1.314Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1414_4007">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
  <ng-template #errorIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12"
        cy="12"
        r="11.25"
        fill="#FDEDEB"
        stroke="#FF3B39"
        stroke-width="1.5"
      />
      <path
        fill="#FF3B39"
        d="M10.5 16.5c0-.398.158-.78.438-1.06.28-.282.66-.44 1.057-.44h.01c.397 0 .777.158 1.057.44A1.503 1.503 0 0 1 12.005 18h-.01c-.397 0-.777-.158-1.057-.44a1.503 1.503 0 0 1-.438-1.06Zm2.616-9.375A1.127 1.127 0 0 0 11.995 6a1.12 1.12 0 0 0-1.121 1.125v5.25a1.127 1.127 0 0 0 1.12 1.125 1.12 1.12 0 0 0 1.122-1.125v-5.25Z"
      />
    </svg>
  </ng-template>
</div>
<div
  class="alerts-item-text"
  [class.alerts-item-text-success]="
    !notification.code || notification.code === 200
  "
>
  {{ notification.message }}
  <div *ngIf="notification.code">Code: {{ notification.code }}.</div>
  <div *ngIf="notification.errorId">ErrorId: {{ notification.errorId }}.</div>
</div>
<div class="alerts-item-close" (click)="onCloseNotification(); (false)">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    fill="none"
    viewBox="0 0 10 10"
  >
    <path
      stroke="#2F3335"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M9 1 1 9M1 1l8 8"
    />
  </svg>
</div>
