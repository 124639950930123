import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable } from 'rxjs';
// TODO: IConfig -> Config
import { API_CONFIG_TOKEN, IConfig } from '../api-data-access.tokens';

@Injectable()
export class ApiService {
  constructor(
    // TODO: remove _
    private readonly _httpClient: HttpClient,
    @Inject(API_CONFIG_TOKEN) private readonly _config: IConfig
  ) {}

  get<T>(path: string, version: 'v1' = 'v1'): Observable<T> {
    const endpoint = this.getEndpointForVersion(version);

    const url = new URL(path, endpoint);

    return this._httpClient
      .get<IResponse<T>>(url.href)
      .pipe(map(this.handleResponse), catchError(this.handleErrorResponse));
  }

  post<T, D = any>(path: string, data: D, version: 'v1' = 'v1'): Observable<T> {
    const endpoint = this.getEndpointForVersion(version);

    const url = new URL(path, endpoint);

    return this._httpClient
      .post<IResponse<T>>(url.href, data)
      .pipe(map(this.handleResponse), catchError(this.handleErrorResponse));
  }

  put<T, D = any>(path: string, data: D, version: 'v1' = 'v1'): Observable<T> {
    const endpoint = this.getEndpointForVersion(version);

    const url = new URL(path, endpoint);

    return this._httpClient
      .put<IResponse<T>>(url.href, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .pipe(map(this.handleResponse), catchError(this.handleErrorResponse));
  }

  delete<T>(path: string, version: 'v1' = 'v1'): Observable<T> {
    const endpoint = this.getEndpointForVersion(version);

    const url = new URL(path, endpoint);

    return this._httpClient
      .delete<IResponse<T>>(url.href)
      .pipe(map(this.handleResponse), catchError(this.handleErrorResponse));
  }

  private handleResponse<T>(response: IResponse<T>): T {
    if (response.code !== 0) {
      throw new Error(
        `Invalid response: ${response.code}; ${JSON.stringify(
          response.payload
        )}`
      );
    }

    return response.payload;
  }

  private handleErrorResponse<T>(response: HttpErrorResponse): T {
    const error = response.error as IErrorResponse;

    throw new Error(`Error response: ${error.code}; ${error.message}`);
  }

  private getEndpointForVersion(version: 'v1'): string {
    switch (version) {
      case 'v1':
        return new URL(`/api/${version}/`, this._config.apiUrl).href;
      default:
        throw new Error('Incorrect API version');
    }
  }
}

interface IResponse<T> {
  code: number;
  payload: T;
}

interface IErrorResponse {
  code: number;
  message: string;
}
