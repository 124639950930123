import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@edgvr-front/api/data-access';
import { AccountId } from '@edgvr-front/accounts/domain';
import {
  CompanyAccount,
  CompanyAccountType,
  CompanyName,
  CreateCompanyAccount,
  UpdateCompanyAccount,
} from '@edgvr-front/companies/domain';

@Injectable()
export class CompanyAccountsService {
  constructor(private readonly apiService: ApiService) {}

  loadCompanyAccounts(data?: LoadCompanyAccountsReq) {
    const params = new URLSearchParams();
    if (data?.type) {
      params.append('type', data.type);
    }

    if (data?.companyName) {
      params.append('company', data.companyName);
    }

    return this.apiService
      .get<{ accounts: CompanyAccount[] }>(`company/accounts?${params}`)
      .pipe(map((_) => _.accounts));
  }

  createCompanyAccount(data: CreateCompanyAccount, companyName?: CompanyName) {
    const params = new URLSearchParams();
    if (companyName) {
      params.append('company', companyName);
    }

    return this.apiService
      .post<{ account: CompanyAccount }>(`company/accounts?${params}`, {
        email: data.email,
        type: data.type,
        workshopIds: data.workshops?.map((_) => _.id),
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        region: data.region,
      })
      .pipe(map((_) => _.account));
  }

  updateCompanyAccount(data: UpdateCompanyAccount, companyName?: CompanyName) {
    const params = new URLSearchParams();
    if (companyName) {
      params.append('company', companyName);
    }

    return this.apiService
      .put<{ account: CompanyAccount }>(
        `company/accounts/${data.id}?${params}`,
        {
          email: data.email,
          type: data.type,
          workshopIds: data.workshops?.map((_) => _.id),
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          region: data.region,
        }
      )
      .pipe(map((_) => _.account));
  }

  deleteCompanyAccount(id: AccountId, companyName?: CompanyName) {
    const params = new URLSearchParams();
    if (companyName) {
      params.append('company', companyName);
    }

    return this.apiService.delete<void>(`company/accounts/${id}?${params}`);
  }

  resetPasswordCompanyAccount(id: AccountId, companyName?: CompanyName) {
    const params = new URLSearchParams();
    if (companyName) {
      params.append('company', companyName);
    }

    return this.apiService.put<void>(
      `company/accounts/${id}/reset-password?${params}`,
      {}
    );
  }
}

interface LoadCompanyAccountsReq {
  type?: CompanyAccountType;
  companyName?: CompanyName;
}
