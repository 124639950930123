import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Account, AccountId } from '@edgvr-front/accounts/domain';
import {
  CompanyAccount,
  CompanyAccountType,
  CompanyName,
  CreateCompanyAccount,
  UpdateCompanyAccount,
} from '@edgvr-front/companies/domain';
import { CompanyAccountsActions } from '../actions';
import { CompanyAccountsSelectors } from '../selectors';

@Injectable()
export class CompanyAccountsFacade {
  companyAccounts$ = this.store.pipe(
    select(CompanyAccountsSelectors.companyAccounts)
  );
  isLoading$ = this.store.pipe(select(CompanyAccountsSelectors.isLoading));
  isUpdating$ = this.store.pipe(select(CompanyAccountsSelectors.isUpdating));
  selectedCompanyAccount$ = this.store.pipe(
    select(CompanyAccountsSelectors.selectedCompanyAccount)
  );

  constructor(private readonly store: Store) {}

  loadCompanyAccounts(data?: LoadCompanyAccountsReq) {
    this.store.dispatch(
      CompanyAccountsActions.loadCompanyAccounts({
        data,
      })
    );
  }

  selectCompanyAccount(id: AccountId) {
    this.store.dispatch(CompanyAccountsActions.selectCompanyAccount({ id }));
  }

  unSelectCompanyAccount() {
    this.store.dispatch(CompanyAccountsActions.unSelectCompanyAccount());
  }

  createCompanyAccount(data: CreateCompanyAccount, companyName?: CompanyName) {
    this.store.dispatch(
      CompanyAccountsActions.createCompanyAccount({
        data,
        companyName,
      })
    );
  }

  updateCompanyAccount(
    data: { account: CompanyAccount; update: UpdateCompanyAccount },
    companyName?: CompanyName
  ) {
    this.store.dispatch(
      CompanyAccountsActions.updateCompanyAccount({
        account: data.account,
        update: data.update,
        companyName,
      })
    );
  }

  deleteCompanyAccount(accountId: AccountId, companyName?: CompanyName) {
    this.store.dispatch(
      CompanyAccountsActions.deleteCompanyAccount({
        accountId,
        companyName,
      })
    );
  }

  resetPasswordCompanyAccount(account: Account, companyName?: CompanyName) {
    this.store.dispatch(
      CompanyAccountsActions.resetPasswordCompanyAccount({
        account,
        companyName,
      })
    );
  }

  cleanCompanyAccounts() {
    this.store.dispatch(CompanyAccountsActions.cleanCompanyAccounts());
  }
}

interface LoadCompanyAccountsReq {
  type?: CompanyAccountType;
  companyName?: CompanyName;
}
