import { createAction, props } from '@ngrx/store';
import { NotificationEntity } from '@edgvr-front/notifications/domain';

export const addNotification = createAction(
  '[Notifications/UI] Add Notification',
  props<{ notification: NotificationEntity }>()
);

export const deleteNotification = createAction(
  '[Notifications/UI] Delete Notification',
  props<{ notification: NotificationEntity }>()
);
