import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesUtilCompanyUrlModule } from '@edgvr-front/companies/util/company-url';
import { UtilCommonModule } from '@edgvr-front/util/common';
import Directives from './directives';
import Services from './services';

@NgModule({
  declarations: [...Directives],
  imports: [CommonModule, CompaniesUtilCompanyUrlModule, UtilCommonModule],
  exports: [...Directives],
  providers: [...Services],
})
export class CompaniesFeatureCompanyAccessModule {}
