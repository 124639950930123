import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs';
import { AdminsActions } from '../actions';
import { AdminsService } from '../services';

@Injectable()
export class AdminsEffects {
  loadAdmins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsActions.loadAdmins),
      fetch({
        run: () => {
          return this.adminsService
            .listAdmins()
            .pipe(map((_) => AdminsActions.loadAdminsSuccess({ admins: _ })));
        },
        onError: (action, error) => AdminsActions.loadAdminsFailure({ error }),
      })
    )
  );

  createAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsActions.createAdmin),
      fetch({
        run: (action) => {
          return this.adminsService
            .createAdmin(action.createAdmin)
            .pipe(map((_) => AdminsActions.createAdminSuccess({ admin: _ })));
        },
        onError: (action, error) => AdminsActions.createAdminFailure({ error }),
      })
    )
  );

  updateAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsActions.updateAdmin),
      fetch({
        run: (action) => {
          return this.adminsService
            .updateAdmin(action.update)
            .pipe(map((_) => AdminsActions.updateAdminSuccess({ admin: _ })));
        },
        onError: (action, error) => AdminsActions.updateAdminFailure({ error }),
      })
    )
  );

  deleteAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsActions.deleteAdmin),
      fetch({
        run: (action) => {
          return this.adminsService.deleteAdmin(action.adminId).pipe(
            map((_) =>
              AdminsActions.deleteAdminSuccess({
                adminId: action.adminId,
              })
            )
          );
        },
        onError: (action, error) => AdminsActions.deleteAdminFailure({ error }),
      })
    )
  );

  resetPasswordAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsActions.resetPasswordAdmin),
      fetch({
        run: (action) => {
          return this.adminsService.resetPasswordAdmin(action.account).pipe(
            map((_) =>
              AdminsActions.resetPasswordAdminSuccess({
                account: action.account,
              })
            )
          );
        },
        onError: (action, error) =>
          AdminsActions.resetPasswordAdminFailure({ error }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly adminsService: AdminsService
  ) {}
}
