import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COMPANY_ACCOUNTS_FEATURE_KEY,
  CompanyAccountsState,
  companyAccountsAdapter,
} from '../reducers';

export const companyAccountsState = createFeatureSelector<CompanyAccountsState>(
  COMPANY_ACCOUNTS_FEATURE_KEY
);

const { selectAll } = companyAccountsAdapter.getSelectors();

export const isLoading = createSelector(
  companyAccountsState,
  (state: CompanyAccountsState) => state.isLoading
);

export const isUpdating = createSelector(
  companyAccountsState,
  (state: CompanyAccountsState) => state.isUpdating
);

export const companyAccounts = createSelector(
  companyAccountsState,
  (state: CompanyAccountsState) => selectAll(state)
);

export const selectedCompanyAccount = createSelector(
  companyAccountsState,
  (state: CompanyAccountsState) =>
    state.selectedAccountId
      ? state.entities[state.selectedAccountId] || null
      : null
);
