import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IConfig, TOKEN_CONFIG_TOKEN } from './token-data-access.tokens';
import { TokenService } from './services';

@NgModule({
  imports: [CommonModule],
  providers: [TokenService],
})
export class TokenDataAccessModule {
  static forRoot(config: IConfig): ModuleWithProviders<TokenDataAccessModule> {
    return {
      ngModule: TokenDataAccessModule,
      providers: [
        {
          provide: TOKEN_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
