import { Inject, Injectable } from '@angular/core';
import { CompanyName } from '@edgvr-front/companies/domain';
import { COMPANY_BASE_URL } from '../../companies-util-company-url.token';

@Injectable()
export class CompanyUrlService {
  constructor(@Inject(COMPANY_BASE_URL) private companyBaseUrl: string) {}

  companyUrl(companyName: CompanyName): string {
    const { protocol, host } = new URL(this.companyBaseUrl);

    return `${protocol}//${companyName}.${host}`;
  }

  companyName(url: string): CompanyName {
    const { host } = new URL(url);

    const [company] = host.split('.');

    return company;
  }
}
