import { Company } from '@edgvr-front/companies/domain';
import { createReducer, on, Action } from '@ngrx/store';
import { CompanyActions } from '../actions';

export const COMPANY_FEATURE_KEY = 'company';

export interface CompanyState {
  company: null | Company;
  isLoading: boolean;
}

export const initialCompanyState: CompanyState = {
  company: null,
  isLoading: false,
};

const reducer = createReducer(
  initialCompanyState,
  on(CompanyActions.loadCompany, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CompanyActions.loadCompanySuccess, (state, { company }) => ({
    ...state,
    company,
    isLoading: false,
  })),
  on(CompanyActions.loadCompanyFailure, (state) => ({
    ...state,
    isLoading: false,
  }))
);

export function companyReducer(
  state: CompanyState | undefined,
  action: Action
) {
  return reducer(state, action);
}
