import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class RedirectToService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router
  ) {}

  async redirect(url: string) {
    const isSameOrigin = url.startsWith(this.document.location.origin);
    if (isSameOrigin) {
      const [, urlToNavigate] = url.split(this.document.location.origin);

      return this.router.navigateByUrl(urlToNavigate);
    }

    this.document.location.href = url;

    return true;
  }
}
