import {
  Directive,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { AccountAccessService } from '../../services';

@Directive({
  selector: '[edgvrFrontAccountCompanyAccess]',
})
export class AccountCompanyAccessDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly accountAccess: AccountAccessService
  ) {}

  ngOnInit() {
    this.accountAccess
      .accountCompanyAccess()
      .pipe(
        tap((_) => this.createEmbeddedView(_)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createEmbeddedView(toRender: boolean) {
    this.viewContainer.clear();

    if (toRender) {
      // https://github.com/angular/angular/issues/12243
      return this.viewContainer
        .createEmbeddedView(this.templateRef)
        .detectChanges();
    }
  }
}
