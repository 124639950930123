import { inject } from '@angular/core';
import { RedirectToService } from '@edgvr-front/util/common';
import { COMPANY_ACCESS_REJECTED_URL } from '../../companies-feature-company-access.token';
import { CompanyAccessService } from '../../services';

export const adminCompanyAccessGuard = async () => {
  const companyAccessService = inject(CompanyAccessService);
  const redirectToService = inject(RedirectToService);
  const companyAccessRejectedUrl = inject(COMPANY_ACCESS_REJECTED_URL);

  const isAdminCompany = companyAccessService.isAdminCompany();
  if (!isAdminCompany) {
    await redirectToService.redirect(companyAccessRejectedUrl);
  }

  return isAdminCompany;
};
