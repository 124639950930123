import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AccountFacade } from '@edgvr-front/account/data-access';
import { Companies, CompanyAccountTypes } from '@edgvr-front/companies/domain';
import { CompanyUrlService } from '@edgvr-front/companies/util/company-url';
import { map, Observable } from 'rxjs';

@Injectable()
export class AccountAccessService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly accountFacade: AccountFacade,
    private readonly companyUrlService: CompanyUrlService
  ) {}

  accountAccess(): Observable<boolean> {
    return this.accountFacade.accountJwt$.pipe(map((_) => !!_));
  }

  accountCompanyAccess(): Observable<boolean> {
    return this.accountFacade.accountJwt$.pipe(
      map((_) => (_ ? !!_.companies[this.companyName] : false))
    );
  }

  accountAdminCompanyAccess(): Observable<boolean> {
    return this.accountFacade.accountJwt$.pipe(
      map((_) => (_ ? !!_.companies[Companies.Admin] : false))
    );
  }

  accountCompanyAdminAccess(): Observable<boolean> {
    return this.accountFacade.accountJwt$.pipe(
      map(
        (_) =>
          _?.companies[this.companyName]?.includes(
            CompanyAccountTypes.CompanyAdmin
          ) || false
      )
    );
  }

  accountCompanyHostAccess(): Observable<boolean> {
    return this.accountFacade.accountJwt$.pipe(
      map(
        (_) =>
          _?.companies[this.companyName]?.includes(
            CompanyAccountTypes.CompanyHost
          ) || false
      )
    );
  }

  private get companyName() {
    return this.companyUrlService.companyName(
      this.document.location.toString()
    );
  }
}
