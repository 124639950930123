export const environment = {
  production: true,
  apiUrl: 'https://api.edg-pro.com',
  frontUrl: 'https://edg-pro.com',
  portalUrl: 'https://edg-pro.com',
  domain: 'edg-pro.com',
  tokenName: 'token.edg-pro.com',
  sentry: {
    dsn: 'https://20a531cdd5b543a6ae19bd66d43fa71f@o4504261033000960.ingest.sentry.io/4504261120163840',
    env: 'production',
  },
  binaries: {
    installer: 'https://cdn.edg-pro.com/binaries/Installer.zip',
    moduleBuilder: 'https://cdn.edg-pro.com/binaries/ModuleBuilder.zip',
  },
  contact: 'info@edg-pro.com',
};
