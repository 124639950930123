import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NotificationEntity } from '@edgvr-front/notifications/domain';

@Component({
  selector: 'edgvr-front-ui-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() notification!: NotificationEntity;

  @Output() closeNotification = new EventEmitter<NotificationEntity>();

  onCloseNotification() {
    this.closeNotification.emit(this.notification);
  }
}
