import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { timer } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CompanyAccountsActions } from '../actions';
import { CompanyAccountsService } from '../services';

@Injectable()
export class CompanyAccountsEffects {
  loadCompanyAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyAccountsActions.loadCompanyAccounts),
      fetch({
        run: (action) => {
          return this.companyAccountsService
            .loadCompanyAccounts(action.data)
            .pipe(
              map((_) =>
                CompanyAccountsActions.loadCompanyAccountsSuccess({
                  accounts: _,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyAccountsActions.loadCompanyAccountsFailure({
            error: error.message,
          }),
      })
    )
  );

  selectCompanyAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyAccountsActions.selectCompanyAccount),
      mergeMap((_) => timer(0).pipe(map(() => _))),
      fetch({
        run: (action) =>
          CompanyAccountsActions.selectCompanyAccountSuccess({ id: action.id }),
      })
    )
  );

  unSelectCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyAccountsActions.unSelectCompanyAccount),
      mergeMap((_) => timer(0).pipe(map(() => _))),
      fetch({
        run: () => CompanyAccountsActions.unSelectCompanyAccountSuccess(),
      })
    )
  );

  createCompanyAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyAccountsActions.createCompanyAccount),
      fetch({
        run: (action) => {
          return this.companyAccountsService
            .createCompanyAccount(action.data, action.companyName)
            .pipe(
              map((_) =>
                CompanyAccountsActions.createCompanyAccountSuccess({
                  account: _,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyAccountsActions.createCompanyAccountFailure({
            error: error.message,
          }),
      })
    )
  );

  updateCompanyAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyAccountsActions.updateCompanyAccount),
      fetch({
        run: (action) => {
          return this.companyAccountsService
            .updateCompanyAccount(action.update, action.companyName)
            .pipe(
              map((_) =>
                CompanyAccountsActions.updateCompanyAccountSuccess({
                  account: _,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyAccountsActions.updateCompanyAccountFailure({
            account: action.account,
            error: error.message,
          }),
      })
    )
  );

  deleteCompanyAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyAccountsActions.deleteCompanyAccount),
      fetch({
        run: (action) => {
          return this.companyAccountsService
            .deleteCompanyAccount(action.accountId, action.companyName)
            .pipe(
              map((_) =>
                CompanyAccountsActions.deleteCompanyAccountSuccess({
                  accountId: action.accountId,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyAccountsActions.deleteCompanyAccountFailure({
            error: error.message,
          }),
      })
    )
  );

  resetPasswordCompanyAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyAccountsActions.resetPasswordCompanyAccount),
      fetch({
        run: (action) => {
          return this.companyAccountsService
            .resetPasswordCompanyAccount(action.account.id, action.companyName)
            .pipe(
              map((_) =>
                CompanyAccountsActions.resetPasswordCompanyAccountSuccess({
                  account: action.account,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyAccountsActions.resetPasswordCompanyAccountFailure({
            error: error.message,
          }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly companyAccountsService: CompanyAccountsService
  ) {}
}
