import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  Company,
  CompanyId,
  CreateCompany,
  UpdateCompany,
} from '@edgvr-front/companies/domain';
import { CompaniesActions } from '../actions';
import { CompaniesSelectors } from '../selectors';

@Injectable()
export class CompaniesFacade {
  isLoading$ = this.store.pipe(select(CompaniesSelectors.isLoading));
  isUpdating$ = this.store.pipe(select(CompaniesSelectors.isUpdating));
  companies$ = this.store.pipe(select(CompaniesSelectors.companies));
  selectedCompany$ = this.store.pipe(
    select(CompaniesSelectors.selectedCompany)
  );

  constructor(private readonly store: Store) {}

  loadCompanies() {
    this.store.dispatch(CompaniesActions.loadCompanies());
  }

  selectCompany(id: CompanyId) {
    this.store.dispatch(CompaniesActions.selectCompany({ id }));
  }

  unSelectCompany() {
    this.store.dispatch(CompaniesActions.unSelectCompany());
  }

  createCompany(createCompany: CreateCompany) {
    this.store.dispatch(CompaniesActions.createCompany({ createCompany }));
  }

  updateCompany(data: { company: Company; update: UpdateCompany }) {
    this.store.dispatch(
      CompaniesActions.updateCompany({
        company: data.company,
        update: data.update,
      })
    );
  }

  deleteCompany(companyId: CompanyId) {
    this.store.dispatch(CompaniesActions.deleteCompany({ companyId }));
  }
}
