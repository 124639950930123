import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountActions } from '@edgvr-front/account/data-access';
import { AdminsActions } from '@edgvr-front/admins/data-access';
import { ApiActions } from '@edgvr-front/api/data-access';
import { CompanyAccountsActions } from '@edgvr-front/companies/data-access';
import { NotificationsFacade } from '@edgvr-front/notifications/data-access';
import { NotificationEntity } from '@edgvr-front/notifications/domain';
import { Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  concatMap,
  filter,
  interval,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'edgvr-front-feature-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private static MAX_NOTIFICATIONS = 5;
  private static DELETE_NOTIFICATION_INTERVAL_MS = 15_000;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly actions$: Actions,
    private readonly notificationsFacade: NotificationsFacade
  ) {}

  ngOnInit() {
    interval(NotificationsComponent.DELETE_NOTIFICATION_INTERVAL_MS)
      .pipe(
        concatMap((_) =>
          this.notificationsFacade.notifications$.pipe(
            take(1),
            tap((_) =>
              _[0] ? this.notificationsFacade.deleteNotification(_[0]) : null
            )
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.notificationsFacade.notifications$
      .pipe(
        filter((_) => _.length > NotificationsComponent.MAX_NOTIFICATIONS),
        tap((_) =>
          _[0] ? this.notificationsFacade.deleteNotification(_[0]) : null
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.actions$
      .pipe(
        tap((_) => this.onAction(_)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get notifications$() {
    return this.notificationsFacade.notifications$;
  }

  onCloseNotification(notification: NotificationEntity) {
    this.notificationsFacade.deleteNotification(notification);
  }

  private onAction(action: Action) {
    const id = uuid();

    switch (action.type) {
      case ApiActions.error.type: {
        return this.notificationsFacade.addNotification({
          id,
          ...(action as ReturnType<typeof ApiActions.error>),
        });
      }
      case AccountActions.reSignInSuccess.type:
        return this.notificationsFacade.addNotification({
          id,
          message: 'Companies successfully updated.',
        });
      case AccountActions.updatePasswordSuccess.type:
        return this.notificationsFacade.addNotification({
          id,
          message: 'Password successfully updated.',
        });
      case AccountActions.updateMeSuccess.type:
        return this.notificationsFacade.addNotification({
          id,
          message: 'Profile successfully updated.',
        });
      case AdminsActions.updateAdminSuccess.type:
        return this.notificationsFacade.addNotification({
          id,
          message: `Admin ${
            (action as ReturnType<typeof AdminsActions.updateAdminSuccess>)
              .admin.email
          } has been updated successfully.`,
        });
      case CompanyAccountsActions.updateCompanyAccountSuccess.type:
        return this.notificationsFacade.addNotification({
          id,
          message: `Account ${
            (
              action as ReturnType<
                typeof CompanyAccountsActions.updateCompanyAccountSuccess
              >
            ).account.email
          } has been updated successfully.`,
        });
      case AdminsActions.resetPasswordAdminSuccess.type:
        return this.notificationsFacade.addNotification({
          id,
          message: `New password has been sent to ${
            (
              action as ReturnType<
                typeof AdminsActions.resetPasswordAdminSuccess
              >
            ).account.email
          }.`,
        });
      case CompanyAccountsActions.resetPasswordCompanyAccountSuccess.type:
        return this.notificationsFacade.addNotification({
          id,
          message: `New password has been sent to ${
            (
              action as ReturnType<
                typeof CompanyAccountsActions.resetPasswordCompanyAccountSuccess
              >
            ).account.email
          }.`,
        });
    }
  }
}
