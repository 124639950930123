import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CookieModule } from 'ngx-cookie';
import { TokenDataAccessModule } from '@edgvr-front/token/data-access';
import { AccountEffects } from './+state/effects';
import { AccountFacade } from './+state/facades';
import { ACCOUNT_FEATURE_KEY, accountReducer } from './+state/reducers';
import { AccountService } from './+state/services';

@NgModule({
  imports: [
    CommonModule,
    CookieModule.withOptions(),
    TokenDataAccessModule,
    StoreModule.forFeature(ACCOUNT_FEATURE_KEY, accountReducer),
    EffectsModule.forFeature([AccountEffects]),
  ],
  providers: [AccountFacade, AccountService],
})
export class AccountDataAccessModule {}
