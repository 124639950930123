import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  CompaniesEffects,
  CompanyAccountsEffects,
  CompanyEffects,
} from './+state/effects';
import {
  CompaniesFacade,
  CompanyAccountsFacade,
  CompanyFacade,
} from './+state/facades';
import {
  COMPANIES_FEATURE_KEY,
  COMPANY_ACCOUNTS_FEATURE_KEY,
  companiesReducer,
  companyAccountsReducer,
  COMPANY_FEATURE_KEY,
  companyReducer,
} from './+state/reducers';
import {
  CompaniesService,
  CompanyAccountsService,
  CompanyService,
} from './+state/services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(COMPANIES_FEATURE_KEY, companiesReducer),
    StoreModule.forFeature(
      COMPANY_ACCOUNTS_FEATURE_KEY,
      companyAccountsReducer
    ),
    StoreModule.forFeature(COMPANY_FEATURE_KEY, companyReducer),
    EffectsModule.forFeature([
      CompaniesEffects,
      CompanyAccountsEffects,
      CompanyEffects,
    ]),
  ],
  providers: [
    CompaniesFacade,
    CompanyAccountsFacade,
    CompanyFacade,
    CompaniesService,
    CompanyAccountsService,
    CompanyService,
  ],
})
export class CompaniesDataAccessModule {}
