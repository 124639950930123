import { Injectable } from '@angular/core';
import { NotificationEntity } from '@edgvr-front/notifications/domain';
import { select, Store } from '@ngrx/store';
import { NotificationsActions } from '../actions';
import { NotificationsSelectors } from '../selectors';

@Injectable()
export class NotificationsFacade {
  notifications$ = this.store.pipe(
    select(NotificationsSelectors.notifications)
  );

  constructor(private readonly store: Store) {}

  addNotification(notification: NotificationEntity) {
    this.store.dispatch(NotificationsActions.addNotification({ notification }));
  }

  deleteNotification(notification: NotificationEntity) {
    this.store.dispatch(
      NotificationsActions.deleteNotification({ notification })
    );
  }
}
