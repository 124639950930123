import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@edgvr-front/api/data-access';
import { Company, CompanyName } from '@edgvr-front/companies/domain';

@Injectable()
export class CompanyService {
  constructor(private readonly apiService: ApiService) {}

  loadCompany(companyName?: CompanyName) {
    const params = new URLSearchParams();
    if (companyName) {
      params.append('company', companyName);
    }

    return this.apiService
      .get<{ company: Company }>(`company/${params}`)
      .pipe(map((_) => _.company));
  }
}
