import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdminsEffects } from './+state/effects';
import { AdminsFacade } from './+state/facades';
import { ADMINS_FEATURE_KEY, adminsReducer } from './+state/reducers';
import { AdminsService } from './+state/services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ADMINS_FEATURE_KEY, adminsReducer),
    EffectsModule.forFeature([AdminsEffects]),
  ],
  providers: [AdminsFacade, AdminsService],
})
export class AdminsDataAccessModule {}
