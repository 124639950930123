import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ACCOUNT_FEATURE_KEY, AccountState } from '../reducers';

const accountState = createFeatureSelector<AccountState>(ACCOUNT_FEATURE_KEY);

export const account = createSelector(
  accountState,
  (state: AccountState) => state.account
);

export const isLoading = createSelector(
  accountState,
  (state: AccountState) => state.isLoading
);
