import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { asyncScheduler, filter, map, observeOn, switchMap, tap } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ApiActions } from '@edgvr-front/api/data-access';
import { ExposedHeaders } from '@edgvr-front/api/domain';
import { TokenService } from '@edgvr-front/token/data-access';
import { AccountActions } from '../actions';
import { AccountService } from '../services';
import { AccountFacade } from '../facades';

@Injectable()
export class AccountEffects {
  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.signIn),
      fetch({
        run: (action) =>
          this.accountService
            .singIn({ email: action.email, password: action.password })
            .pipe(map((_) => AccountActions.signInSuccess({ token: _.token }))),
        onError: (action, error) =>
          AccountActions.signInFailure({ error: error.message }),
      })
    )
  );

  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.signOut),
        tap((_) => this.tokenService.removeToken())
      ),
    { dispatch: false }
  );

  navigateSameUrlOnAccountChange$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((_) => _ instanceof NavigationEnd),
        switchMap((_) => this.accountFacade.accountJwt$),
        distinctUntilChanged((x, y) => x?.id === y?.id),
        tap((_) => this.router.navigateByUrl(this.router.url))
      ),
    { dispatch: false }
  );

  setTokenOnSignIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.signInSuccess),
        tap((_) => this.tokenService.setToken(_.token))
      ),
    { dispatch: false }
  );

  onToken$ = createEffect(() =>
    this.tokenService.token$.pipe(
      observeOn(asyncScheduler),
      distinctUntilChanged(),
      switchMap((_) =>
        _ ? [AccountActions.me()] : [AccountActions.noAuthorized()]
      )
    )
  );

  me$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.me),
      fetch({
        run: () =>
          this.accountService
            .me()
            .pipe(map((_) => AccountActions.meSuccess({ account: _ }))),
        onError: (action, error) =>
          AccountActions.meFailure({ error: error.message }),
      })
    )
  );

  updateMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateMe),
      fetch({
        run: (action) =>
          this.accountService
            .updateMe(action.update)
            .pipe(
              map((_) => AccountActions.updateMeSuccess({ account: _.account }))
            ),
        onError: (action, error) =>
          AccountActions.updateMeFailure({
            account: action.account,
            error: error.message,
          }),
      })
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updatePassword),
      fetch({
        run: (action) =>
          this.accountService
            .updatePassword(action.updatePassword)
            .pipe(map((_) => AccountActions.updatePasswordSuccess())),
        onError: (action, error) =>
          AccountActions.updatePasswordFailure({ error: error.message }),
      })
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.resetPassword),
      fetch({
        run: (action) =>
          this.accountService
            .resetPassword(action.resetPassword)
            .pipe(map((_) => AccountActions.resetPasswordSuccess())),
        onError: (action, error) =>
          AccountActions.resetPasswordFailure({ error: error.message }),
      })
    )
  );

  apiExpiredToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiActions.noAuthorized),
      map((_) => AccountActions.signOut())
    )
  );

  apiHeaderAccountToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApiActions.edgVrHeader),
        filter((_) => _.key === ExposedHeaders.AccountToken),
        tap((_) => this.tokenService.setToken(_.value))
      ),
    { dispatch: false }
  );

  reSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.reSignIn),
      fetch({
        run: () =>
          this.accountService
            .reSignIn()
            .pipe(map((_) => AccountActions.reSignInSuccess())),
        onError: (action, error) =>
          AccountActions.reSignInFailure({ error: error.message }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly tokenService: TokenService,
    private readonly accountService: AccountService,
    private readonly accountFacade: AccountFacade
  ) {}
}
