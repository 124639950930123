import { NotificationEntity } from '@edgvr-front/notifications/domain';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { NotificationsActions } from '../actions';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface NotificationsState extends EntityState<NotificationEntity> {}

export const notificationsAdapter: EntityAdapter<NotificationEntity> =
  createEntityAdapter<NotificationEntity>({
    selectId: (_) => _.id,
  });

export const initialNotificationsState: NotificationsState =
  notificationsAdapter.getInitialState();

const reducer = createReducer(
  initialNotificationsState,
  on(NotificationsActions.addNotification, (state, { notification }) =>
    notificationsAdapter.addOne(notification, state)
  ),
  on(NotificationsActions.deleteNotification, (state, { notification }) =>
    notificationsAdapter.removeOne(notification.id, state)
  )
);

export function notificationsReducer(
  state: NotificationsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
