import { Injectable, ErrorHandler, Inject } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { SENTRY_ERROR_HANDLER_PARAMS_TOKEN } from '../token';

export interface IParams {
  dsn: string;
  env: string;
  version: string;
}

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    @Inject(SENTRY_ERROR_HANDLER_PARAMS_TOKEN) private readonly _params: IParams
  ) {
    Sentry.setTag('type', 'frontend');
    Sentry.init({
      dsn: this._params.dsn,
      environment: this._params.env,
      release: this._params.version,
    });
  }

  handleError(error: Error): void {
    Sentry.captureException(error);

    if (this._params.env !== 'production') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
