import { createAction, props } from '@ngrx/store';
import {
  ResetPassword,
  UpdateMe,
  UpdatePassword,
} from '@edgvr-front/account/domain';
import {
  Account,
  AccountEmail,
  AccountPassword,
} from '@edgvr-front/accounts/domain';
import { JwtToken } from '@edgvr-front/token/domain';

export const signIn = createAction(
  '[Account] Sign In',
  props<{ email: AccountEmail; password: AccountPassword }>()
);
export const signInSuccess = createAction(
  '[Account] Sign In Success',
  props<{ token: JwtToken }>()
);
export const signInFailure = createAction(
  '[Account] Sign In Failure',
  props<{ error: string }>()
);

export const reSignIn = createAction('[Account] Re Sign In');
export const reSignInSuccess = createAction('[Account] Re Sign In Success');
export const reSignInFailure = createAction(
  '[Account] Re Sign In Failure',
  props<{ error: string }>()
);

export const signOut = createAction('[Account] Sign Out');

export const resetPassword = createAction(
  '[Account] Reset Password',
  props<{ resetPassword: ResetPassword }>()
);
export const resetPasswordSuccess = createAction(
  '[Account] Reset Password Success'
);
export const resetPasswordFailure = createAction(
  '[Account] Reset Password Failure',
  props<{ error: string }>()
);

export const updatePassword = createAction(
  '[Account] Update Password',
  props<{ updatePassword: UpdatePassword }>()
);
export const updatePasswordSuccess = createAction(
  '[Account] Update Password Success'
);
export const updatePasswordFailure = createAction(
  '[Account] Update Password Failure',
  props<{ error: string }>()
);

export const me = createAction('[Account] Me');
export const meSuccess = createAction(
  '[Account] Me Success',
  props<{ account: Account }>()
);
export const meFailure = createAction(
  '[Account] Me Failure',
  props<{ error: string }>()
);

export const updateMe = createAction(
  '[Account] Update Me',
  props<{ account: Account; update: UpdateMe }>()
);
export const updateMeSuccess = createAction(
  '[Account] Update Me Success',
  props<{ account: Account }>()
);
export const updateMeFailure = createAction(
  '[Account] Update Me Failure',
  props<{ account: Account; error: string }>()
);

export const noAuthorized = createAction('[Account] No Authorized');
