import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';

import { TOKEN_CONFIG_TOKEN } from '../token-data-access.tokens';

type IToken = string;

@Injectable()
export class TokenService implements OnDestroy {
  readonly _token$ = new BehaviorSubject<null | IToken>(this._token());
  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(TOKEN_CONFIG_TOKEN) private readonly config: IConfig,
    private readonly cookieService: CookieService
  ) {
    this.monitorToken$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get token() {
    return this._token$.value;
  }

  get token$() {
    return this._token$.pipe(distinctUntilChanged(), takeUntil(this.destroy$));
  }

  setToken(token: string): void {
    this._setToken(token);

    this._token$.next(token);
  }

  removeToken(): void {
    this._removeToken();

    this._token$.next(null);
  }

  private monitorToken$(): Observable<void> {
    return interval(500).pipe(
      map(() => this._token()),
      map((_) => {
        if (this._token$.value === _) {
          return;
        }

        this._token$.next(_);

        return;
      })
    );
  }

  private _token(): null | IToken {
    const token = this.cookieService.get(this.config.tokenName);

    if (token) {
      return token;
    }

    return null;
  }

  private _setToken(token: string): void {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    const options: { expires?: Date; domain?: string; path: string } = {
      expires: oneYearFromNow,
      path: '/',
    };

    if (this.config.domain) {
      options.domain = this.config.domain;
    }

    this.cookieService.put(this.config.tokenName, token, options);
  }

  private _removeToken(): void {
    const options: { domain?: string; path: string } = {
      path: '/',
    };

    if (this.config.domain) {
      options.domain = this.config.domain;
    }

    this.cookieService.remove(this.config.tokenName, options);
  }
}

interface IConfig {
  domain: string;
  tokenName: string;
}
